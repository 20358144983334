var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"title":_vm.$vuetify.breakpoint.lgAndUp ? 'Minha Assinatura' : 'Assinatura',"options":[]}},[_c('div',{staticClass:"expande-horizontal wrap"},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-form',{ref:"formMinhaLoja"},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"fonte expande-horizontal centraliza column wrap"},[_c('h3',{staticClass:"fonte",style:(`color: ${_vm.$theme.primary}`)},[_vm._v(" Plano "+_vm._s(_vm.subscription.plan_id.name)+" ")]),_c('span',{staticClass:"fonte fonteMini grey--text"},[_vm._v("Próxima cobrança: "+_vm._s(_vm.$moment(_vm.subscription.creation_of_next_charge).format( "DD/MM/YYYY" )))]),_c('div',{staticClass:"expande-horizontal pt-3 centraliza"},[_c('v-flex',{attrs:{"xs3":"","md1":""}},[_c('div',{staticClass:"expande-horizontal centraliza"},[_c('div',{staticClass:"change-method-icon-container vitrify-content",class:{
                      'method-selected': _vm.subscription.method === 'bank_slip'
                    },attrs:{"icon":"","color":_vm.$theme.primary},on:{"click":function($event){}}},[_c('v-icon',{attrs:{"color":_vm.subscription.method === 'bank_slip'
                          ? 'white'
                          : _vm.$theme.primary}},[_vm._v("mdi-barcode")]),_c('span',{staticClass:"fonteMini",class:{
                        'white--text': _vm.subscription.method === 'bank_slip',
                        'grey--text': _vm.subscription.method === 'card'
                      }},[_vm._v("Boleto")])],1)])]),_c('v-flex',{attrs:{"xs3":"","md1":""}},[_c('div',{staticClass:"expande-horizontal centraliza"},[_c('div',{staticClass:"change-method-icon-container vitrify-content",class:{
                      'method-selected': _vm.subscription.method === 'card'
                    },attrs:{"icon":"","color":_vm.$theme.primary},on:{"click":function($event){}}},[_c('v-icon',{attrs:{"color":_vm.subscription.method === 'card'
                          ? 'white'
                          : _vm.$theme.primary}},[_vm._v("mdi-credit-card-outline")]),_c('span',{staticClass:"fonteMini",class:{
                        'white--text': _vm.subscription.method === 'card',
                        'grey--text': _vm.subscription.method === 'bank_slip'
                      }},[_vm._v("Cartão")])],1)])])],1)]),_c('div',{staticClass:"pa-6 centraliza expande-horizontal wrap"},[_c('v-flex',{attrs:{"xs12":"","md8":""}},[_c('v-list',{staticClass:"pa-0 ma-0 fonte expande-horizontal",attrs:{"color":"transparent","dense":""}},_vm._l((_vm.charges),function(charge){return _c('v-list-item',{key:charge._id,staticClass:"item-list-charge mb-3"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":_vm.$theme.primary}},[_c('v-icon',{attrs:{"color":"#333"}},[_vm._v("mdi-receipt-text-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$helper.formataSaldo(charge.value / 1000))+" ")]),_c('v-list-item-title',{staticClass:"white--text"},[_vm._v(" Cobrado "+_vm._s(_vm.$moment(charge.charge_fire).format("DD/MM/YYYY"))+" ")]),_c('v-list-item-subtitle',{staticClass:"white--text"},[_vm._v(" Situação: "+_vm._s(charge.current_status)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"font-weight-bold",staticStyle:{"opacity":"0.8"},attrs:{"icon":"","text":"","color":"white"}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)],1)}),1)],1)],1)])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }